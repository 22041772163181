import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Web3ReactProvider} from "@web3-react/core";
import {ExternalProvider, JsonRpcFetchFunc, Web3Provider} from "@ethersproject/providers";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
    return  new Web3Provider(provider, "any");
}

root.render(
    <React.StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
            <App/>
        </Web3ReactProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
